<template>
  <pro-menu-layout>
    <pro-deck
      :title="getRes(proDeckTitle)"
      :cards="items"
      v-slot="{ card }"
      hide-count-badge
    >
      <pro-deck-card v-bind="card" expandable :title="getRes(card.title)">
        <q-list v-if="card.files && card.files.length">
          <q-item
            class="grey-on-hover"
            v-for="file in card.files"
            :key="file.fileId"
          >
            <pro-file
              :file="file"
              show-download-button
              :isShowFilename="false"
            />
          </q-item>
        </q-list>
        <div v-if="card.list && card.list.length">
          <div v-for="(item, index) in card.list" :key="index">
            <div class="row justify-between no-wrap">
              <span class="col-auto" style="font-size: 17px">
                {{ item.title }}
              </span>

              <span class="col-auto" style="color: grey">
                <span>
                  {{ getRes("Form.Section.PublishedOn") }}
                  &nbsp;&nbsp;
                </span>
                <span>{{ formatDate(item.publishDate) }}</span>
              </span>
            </div>

            <q-list>
              <q-item
                class="grey-on-hover"
                v-for="file in item.files"
                :key="file.fileId"
              >
                <pro-file
                  :file="file"
                  show-download-button
                  :isShowFilename="false"
                  download-stream
                />
              </q-item>
            </q-list>

            <q-list v-if="!item.files || item.files.length === 0">
              <q-item class="grey-on-hover">
                <div class="col-12 row justify-between" style="font-size: 17px">
                  <span
                    class="text-primary"
                    v-text="$t('Form.Section.BOQTemplateAddendum')"
                  />
                </div>
              </q-item>
            </q-list>
          </div>
        </div>
      </pro-deck-card>
    </pro-deck>
    <pro-deck
      v-if="boqItems.length > 0"
      :cards="boqItems"
      hide-count-badge
      :pro-report-columns="boqColumns"
      pro-report-key="itemId"
      :pro-report-title="$t('CustMenu.Com.Action.ViewBoqTemplate')"
    >
    </pro-deck>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProFile from "../../../../components/PROSmart/ProFile";

export default {
  components: { ProFile, ProDeckCard, ProDeck, ProMenuLayout },
  data() {
    return {
      items: [],
      workflowCode: "",
      moduleName: "",
      proDeckTitle: "CustMenu.Com.Action.ViewDownloadTenderClarifications",
      boqColumns: [
        {
          name: "item",
          i18NLabel: "Form.Field.Item",
          field: "item",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          defaultShow: false,
        },
        {
          name: "itemCode",
          i18NLabel: "Form.Field.ItemCode",
          field: "itemCode",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          style: "width: 100px;",
        },
        {
          name: "description",
          i18NLabel: "Form.Field.Description",
          field: "description",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "currency",
          i18NLabel: "Form.Field.currency",
          field: "currency",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "quantity",
          i18NLabel: "Form.Field.Quantity",
          field: "quantity",
          align: "left",
          searchable: true,
          sortable: true,
          type: "float",
        },
        {
          name: "unit",
          i18NLabel: "Form.Field.Unit",
          field: "unit",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
      ],
      boqItems: [],
    };
  },

  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },
    /** @param {{files:Array<object>, list:Array<object>}} obj */
    checkFileListIsEmpty(obj) {
      return (
        (!obj.files || obj.files.length === 0) &&
        (!obj.list || obj.list.length === 0)
      );
    },
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.$proSmart.documentUi.getInfo(this, this.tenderId).then((formData) => {
      this.moduleName = formData.moduleName;
    });
    this.items = await this.$proSmart.documentUi.getTenderAttachment(
      this,
      this.$route.params.id
    );

    this.$proSmart.boq.getBoqItems(this, this.tenderId).then((b) => {
      let list = b.boqItemsComponent.list;
      if (list.length > 0) {
        this.boqItems = list;
      }
    });

    if (this.moduleName === "EOI") {
      this.items[0].title = "Form.Section.eoiFormAndAnnexs";
      this.proDeckTitle =
        this.$route.params.userRole === "Tenderer"
          ? "CustMenu.Com.Action.ViewDownloadTenderClarificationsHeaderEOI"
          : "CustMenu.Com.Action.ViewDownloadTenderClarifications";
    } else if (this.moduleName === "PQ") {
      this.items[0].title = "CustMenu.Com.Stepper.PqFormsAndAnnexs";
      this.proDeckTitle =
        this.$route.params.userRole === "Tenderer"
          ? "CustMenu.Com.Action.ViewDownloadTenderClarificationsHeaderPQ"
          : "CustMenu.Com.Action.ViewDownloadTenderClarifications";
    } else if (this.moduleName === "RFI") {
      this.items[0].title = "CustMenu.Com.Stepper.RfiDocumentsAndAnnexs";
      this.proDeckTitle =
        this.$route.params.userRole === "Tenderer"
          ? "CustMenu.Com.Action.ViewDownloadTenderClarificationsRfi"
          : "CustMenu.Com.Action.ViewDownloadTenderClarificationsRfi";
    }
  },
};
</script>

<style scoped lang="scss">
.grey-on-hover {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 0;
  }

  &:hover:before {
    opacity: 1;
  }
}
</style>
